<template>
    <div class="stage-inner-container-padded">
        <div v-show="warnUnsaved" class="p-2 fs-1-5 bg-warning">
            <span class="font-weight-bold mr-2">{{unsavedChanges.length}} Unsaved Changes</span>
            <b-button
                variant="success"
                @click="saveAllChanges"
            >
                Save Changes
            </b-button>
        </div>
        <b-table
            small
            striped 
            hover 
            stacked="lg"
            :items="mappings" 
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :busy="waiting"
        >
            <template #table-busy>
               <wait-dots :title="waitTitle"></wait-dots>
            </template>
            <template #cell(hazard_type_name)="data">
                <span class="fs-1-2-5 font-weight-bold">
                    {{data.item.hazardType.value}}
                </span>
            </template>
            <template #cell()="data">
                <div class="d-flex flex-row align-items-center">
                    <span class="fs-1-25 pr-1" v-if="isChanged(data)">
                        <b-icon-circle-fill variant="info"></b-icon-circle-fill>
                    </span>
                    <b-form-select 
                        v-model="data.value" 
                        :options="allowedOptions"
                        @change="markUpdated(data)"
                        size="sm"
                    >
                    </b-form-select>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;
var async = require("async");

import waitDots from '@/components/subcomponents/WaitingDots.vue'

export default {
    name: 'HazardTypeDeviceTypeSetup',
    components:{
        'wait-dots': waitDots
    },
    props:{
    },
    data(){
        return{
            waiting: false,
            waitTitle: "Loading",
            deviceTypes: [],
            hazardTypes: [],
            rawMapping: [],
            mappings: [],
            fields: [
                {
                    key: 'hazard_type_name',
                    label: 'Hazard Type',
                    sortable: true
                }
            ],
            allowedOptions:[
                {value: true, text: "Sufficient"},
                {value: false, text: "Not Sufficient"},
            ],
            warnUnsaved: false,
            unsavedChanges: [],
            sortBy: null,
            sortDesc: false,
        }
    },
    methods:{
        saveAllChanges(){
            this.waiting = true;
            this.waitTitle = "Saving... Please Stand By.";
            var list = [];
            this.unsavedChanges.forEach((chg)=>{
                var update = _.find(this.mappings, (map)=>{ return map.hazardType.id === chg.hazID; })
                if(update != undefined){
                    var tmp = {
                        hazard_type_id: chg.hazID,
                        allowed_device_types: update.raw.allowed_device_types
                    }
                    // Perform Update To This Mapping
                    chg.changed.forEach((change)=>{
                        if(change.value){
                            // Add it
                            tmp.allowed_device_types.push(change.key);
                        }else{
                            // Remove it
                            tmp.allowed_device_types = _.filter(tmp.allowed_device_types, (typ)=>{ return typ != change.key; })
                        }
                    })
                    list.push(tmp)
                }else{
                    // We can assume that there is no mapping for this hazard type, so long as the hazard type actually exists, then we should be safe to expect this to upsert to the db
                    console.log(`Expected To Find Map With Hazard Type ID: ${chg.hazID}`);
                }
            })
            this.startProcessingUpdates(list);
        },
        startProcessingUpdates(list){
            async.eachSeries(list, 
                (item, callback)=>{
                    butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/config/compliance/hazard_vs_type`, item)
                    .then((response)=>{
                        callback();
                    })
                    .catch((err)=>{
                        if(butils.isError401(err)){
                            butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                        }else if(butils.isError403(err)){
                            butils.createToast(this, 'Permissions To Update Device Hazard vs Type Mapping Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                        }else{
                            console.log(err);
                            butils.createToast(this, 'An Error Occured While Updating Hazard vs Type Mapping', 'Try again, if the problem persists, contact support', 'danger');
                        }
                        callback();
                    })
                }
            )
            .then((ignore)=>{
                this.waiting = false;
                this.unsavedChanges = [];
                this.warnUnsaved = false;
                this.performRequestsOrdered();
            })
            .catch((err)=>{
                this.waiting = false;
                console.log(err);
            })
        },
        isChanged(data){
            var found = _.find(this.unsavedChanges, (uns)=>{ return uns.hazID === data.item.hazardType.id; })
            if(found != undefined){
                var field = _.find(found.changed, (fld)=>{return fld.key === data.field.key; })
                if(field != undefined){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },
        removeUnnescicaryChangesAndWarn(){
            this.mappings.forEach((map)=>{
                // Find changes associated with this hazard type map
                var found = _.find(this.unsavedChanges, (uns)=>{ return uns.hazID == map.hazardType.id; })
                if(found != undefined){
                    found.changed = _.filter(found.changed, (chg)=>{ 
                        if(map.raw.allowed_device_types == null){
                            return chg.value;
                        }else if(map.raw.allowed_device_types.length == 0){
                            return chg.value;
                        }else if(chg.value && map.raw.allowed_device_types.includes(chg.key)){
                            // The type requested allowed, the allowed type is already in the list of allowed types
                            return false;
                        }else if(!chg.value && !map.raw.allowed_device_types.includes(chg.key)){
                            // The type was disallowed, but its already disallowed
                            return false;
                        }else{
                            return true;
                        }
                    })
                    // Remove any unsaved changes entries with NO changes in the change array
                    this.unsavedChanges = _.filter(this.unsavedChanges, (uns)=>{ return uns.changed.length > 0; })
                    if(this.unsavedChanges.length == 0){
                        this.warnUnsaved = false;
                    }else{
                        this.warnUnsaved = true;
                    }
                }else{
                    // No Changes Associated
                }
            })
        },
        markUpdated(data){
            var found = _.find(this.unsavedChanges, (uns)=>{ return uns.hazID === data.item.hazardType.id; })
            if(found != undefined){
                var field = _.find(found.changed, (chg)=>{ return chg.key == data.field.key; })
                if(field != undefined){
                    field.value = data.value;
                    
                }else{
                    found.changed.push({
                        key: data.field.key,
                        label: data.field.label,
                        value: data.value
                    })
                }
            }else{
                this.unsavedChanges.push({
                    index: data.index,
                    hazID: data.item.hazardType.id,
                    hazName: data.item.hazard_type_name,
                    changed: [{
                        key: data.field.key,
                        label: data.field.label,
                        value: data.value
                    }]
                })
            }
            this.removeUnnescicaryChangesAndWarn();
        },
        fetchHazardTypes(){
            return new Promise((resolve, reject)=>{
               butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/choices/hazard_types`)
                .then((response)=>{
                    this.hazardTypes = response.data.result.records;
                    resolve();
                })
                .catch((err)=>{
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Permissions To Fetch Hazard Types Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                    }else{
                        console.log(err);
                        butils.createToast(this, 'An Error Occured While Fetching Hazard Types', 'Try again, if the problem persists, contact support', 'danger');
                    }
                    reject();
                })
            })
            
        },
        fetchDeviceTypes(){
            return new Promise((resolve, reject)=>{
                butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/choices/device_types`)
                .then((response)=>{
                    this.deviceTypes = response.data.result.records;
                    this.fields = [
                        {
                            key: 'hazard_type_name',
                            label: 'Hazard Type',
                            sortable: true
                        }
                    ];
                    this.deviceTypes.forEach((dt)=>{
                        this.fields.push(
                            {
                                key: dt.id,
                                label: dt.value,
                                sortable: true
                            }
                        )
                    })
                    resolve();
                })
                .catch((err)=>{
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Permissions To Fetch Device Types Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                    }else{
                        console.log(err);
                        butils.createToast(this, 'An Error Occured While Fetching Device Types', 'Try again, if the problem persists, contact support', 'danger');
                    }
                    reject();
                })
            })
        },
        fetchHazardTypeMapping(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/config/compliance/hazard_vs_type`)
            .then((response)=>{
                var hazVsType = response.data.result;
                this.rawMapping = hazVsType;
                // Build a map object for every hazard type, even if there is no current map for that hazard type
                const missingMappings = []
                this.mappings = _.map(this.hazardTypes, (ht)=>{
                    var tmp = {};
                    tmp._cellVariants = {};
                    tmp.hazardType = ht;
                    tmp.hazard_type_name = ht.value;
                    var matchedMap = _.find(hazVsType, (htMap)=>{ return htMap.hazard_type_id == ht.id; })
                    if(matchedMap != undefined){
                        tmp.raw = matchedMap;
                        this.deviceTypes.forEach((dt)=>{
                            var foundThisTypeInSet = _.find(tmp.raw.allowed_device_types, (adt)=>{ return adt === dt.id; })
                            if(foundThisTypeInSet != undefined){
                                tmp[dt.id] = true;
                            }else{
                                tmp[dt.id] = false;
                            }
                        })
                    }else{
                        tmp.raw = null;
                        this.deviceTypes.forEach((dt)=>{
                            tmp[dt.id] = false;
                        })
                        // Add this missing mapping to the list for auto-creation
                        missingMappings.push({
                            hazard_type_id: ht.id,
                            allowed_device_types: []
                        })
                        console.log(`No Hazard Mapping Found For Hazard Type ID: ${ht.id} - Value: ${ht.value}`)
                    }
                    return tmp;
                })
                // Not waiting anymore
                this.waiting = false;
                if (missingMappings.length > 0) {
                    // For each hazard type which is not in the map as a mapped hazard type, create it
                    this.startProcessingUpdates(missingMappings)
                }
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permissions To Fetch Device Hazard vs Type Mapping Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Fetching Hazard vs Type Mapping', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        performRequestsOrdered(){
            this.waiting = true;
            var startup = [this.fetchDeviceTypes(), this.fetchHazardTypes()]

            Promise.all(startup)
            .then((ignore)=>{
                this.fetchHazardTypeMapping();
            })
            .catch(()=>{
                this.waiting = false;
            })
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        this.performRequestsOrdered();
    },
    mounted(){
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
</style>