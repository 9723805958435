<template>
    <div class="h-100 w-100">
        <div class="d-flex justify-content-center align-items-center mt-4 overlay-top">
            <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
            <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
            <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
        </div>
        <div :class="`d-flex justify-content-center ${titleSize} mt-4`">
            <span>{{title}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WaitDots',
    props:{
        title: String,
        titleSize:{
            type: String,
            default: ()=>{return 'h3'}
        }
    },
    data(){
        return{}
    },
}
</script>